import { Link } from 'gatsby'
import React from 'react'


const Footer = props => {
  return (
  <footer id='footer'>
    <section>
      <h2>Let's Work Together</h2>
      <p>Have a project you'd like to discuss?</p>
      <ul className='actions'>
        <li className='button' onClick={props.onContact}>
          Get in Touch
        </li>
      </ul>
    </section>
    <section>
      <h2>Contact</h2>
      <dl className='alt'>
        <dt>Phone</dt>
        <dd>(+91) 8860545809</dd>
        <dt>Email</dt>
        <dd>
          <a>hey@arkitvora.com</a>
        </dd>
      </dl>
      <ul className='icons'>
        <li>
          <a
            target='_blank'
            href='https://www.facebook.com/arkitvora'
            className='icon fa-facebook alt'
          >
            <span className='label'>Facebook</span>
          </a>
        </li>
        <li>
          <a
            target='_blank'
            href='https://www.instagram.com/arkitvora'
            className='icon fa-instagram alt'
          >
            <span className='label'>Instagram</span>
          </a>
        </li>
        <li>
          <a
            target='_blank'
            href='https://github.com/24ark'
            className='icon fa-github alt'
          >
            <span className='label'>GitHub</span>
          </a>
        </li>
        <li>
          <a
            target='_blank'
            href='https://www.linkedin.com/in/arkit-vora-92756b60/'
            className='icon fa-linkedin alt'
          >
            <span className='label'>LinkedIn</span>
          </a>
        </li>
      </ul>
    </section>
    <p className='copyright'>&copy; 2020 Arkit Vora. All rights reserved.</p>
  </footer>
)}


export default Footer
